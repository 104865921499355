import React, { useState, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import {
  Accordion,
  AccordionSummary,
  makeStyles,
  Snackbar,
} from "@material-ui/core";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Icons,
  Box,
  Grid,
  SvgIcon,
} from "@src/components";

import { t } from "ttag";
import clsx from "clsx";
import { useUser } from "@src/hooks";
import { Area, getAreasPromise } from "@ats/graphql";
import { scaniaWhite, scaniaBlack } from "@src/constants/colors";
import { UiState, UiEvent } from "@src/machines/ui/ui.machine";
import { ReactComponent as LeftMenu } from "@src/assets/icons/left-menu.svg";
import { ReactComponent as Avatar } from "@src/assets/icons/avatar.svg";
import { ReactComponent as DownArrow } from "@src/assets/icons/down-arrow.svg";
import { ReactComponent as Close } from "@src/assets/icons/close.svg";
import Button from "../../components/Button/Button.component";
import environment from "../../config/environment";

type HeaderProps = {
  text: React.ReactNode;
  handleBack?: () => void;
  sites?: ReadonlyArray<Area>;
  siteMenu?: boolean;
  uiState?: UiState;
  uiSend?: (event: UiEvent) => void;
};

const useStyles = makeStyles(
  {
    root: {
      backgroundColor: scaniaWhite,
      color: scaniaBlack,
      display: "flex",
      flexDirection: "column",
      height: "calc(100vh)",
      width: "272px",
    },
    siteButton: {
      borderRadius: "0px",
      height: "48px",
      width: "inherit",
      justifyContent: "flex-start",
      wordBreak: "keep-all",
      paddingLeft: "40px",
      color: scaniaBlack,
      "&:last-child": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      },
    },
    closeButton: {
      cursor: "pointer",
    },
    borderBottom: {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    header: {
      height: "68px",
      fontSize: "14px",
      lineHeight: "16px",
      display: "flex",
      alignItems: "center",
      paddingLeft: "24px",
      paddingTop: "25px",
      paddingBottom: "25px",
      fontWeight: 700,
      color: scaniaBlack,
      letterSpacing: "-0.01rem",
    },
    devoptions: {
      height: "68px",
      fontSize: "14px",
      lineHeight: "16px",
      display: "flex",
      alignItems: "center",
      paddingLeft: "24px",
      paddingTop: "10px",
      paddingBottom: "10px",
      fontWeight: 300,
      color: scaniaBlack,
      letterSpacing: "-0.01rem",
    },
    accordion: {
      "&:hover, &:focus": {
        backgroundColor: "#EDEFF3",
      },
    },
    label: {
      padding: "3px 20px",
      opacity: 0.7,
      fontWeight: "bold",
    },
    userRole: {
      pointerEvents: "none",
      border: "1px solid",
      margin: "0 20px",
      padding: "15px 10px",
    },
    logout: {
      paddingTop: "15px",
    },
  },
  { index: 1 }
);

// eslint-disable-next-line no-unused-vars
const Header = ({ text, handleBack, sites, uiSend, siteMenu }: HeaderProps) => {
  const {
    root,
    siteButton,
    closeButton,
    borderBottom,
    header,
    accordion,
    label,
    userRole,
    logout,
  } = useStyles();
  const { setRole } = useUser();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [accordionOpen, setAccordionOpen] = useState(false);

  const [
    leftPanelAnchorEl,
    setLeftPanelAnchorEl,
  ] = useState<null | HTMLElement>(null);

  const isOpen = Boolean(anchorEl);
  const isLeftPanelOpen = Boolean(leftPanelAnchorEl);

  const { keycloak } = useKeycloak();

  const close = () => {
    setAnchorEl(null);
    setLeftPanelAnchorEl(null);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);

  const handleSiteSelection = (event: React.MouseEvent<HTMLElement>) =>
    setLeftPanelAnchorEl(event.currentTarget);

  const handleClose = close;
  const handleLogout = () => {
    close();
    keycloak.logout();
  };

  const [sessionCopiedOpen, setSessionCopiedOpen] = useState(false);

  const handleCopySessionClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSessionCopiedOpen(false);
  };

  useEffect(() => {
    setRole("fieldOperator");
    // Set Field Operator as default role
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (uiSend) {
      getAreasPromise().then((response) => {
        uiSend({ type: "FETCH_SITES", sites: response });
      });
    }
  }, [uiSend]);

  return (
    <AppBar position="fixed" color="default">
      <Box bgcolor="background.paper">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          data-testid="menu-appbar-session-copied"
          open={sessionCopiedOpen}
          autoHideDuration={5000}
          onClose={handleCopySessionClose}
        />
        <Box
          style={{
            backgroundColor: "#ffcc00",
            padding: "20px",
            textAlign: "center",
            fontWeight: "bold",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
          }}
        >
          This application will soon be discontinued and should only be used if{" "}
          <a
            href="https://ice-pitpilot-channar.valhalla.prod.aws.scania.com/"
            style={{ color: "#0000EE", textDecoration: "underline" }}
          >
            PitPilot
          </a>{" "}
          is unavailable. A Trouble Report (TR) must document why DICE was used
          instead of PitPilot.
        </Box>
        <Toolbar>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item>
              <Box width={50}>
                {handleBack && (
                  <IconButton
                    onClick={handleBack}
                    edge="start"
                    data-testid="menu-appbar-back"
                  >
                    <Icons.ArrowBackIos />
                  </IconButton>
                )}
                {siteMenu && (
                  <IconButton
                    onClick={handleSiteSelection}
                    edge="start"
                    data-testid="menu-appbar-site-selection"
                  >
                    <LeftMenu />
                  </IconButton>
                )}
                <Menu
                  id="menu-appbar"
                  data-testid="menu-appbar"
                  anchorEl={leftPanelAnchorEl}
                  keepMounted
                  open={isLeftPanelOpen}
                  marginThreshold={0}
                  style={{ left: "-1%" }}
                  onClose={handleClose}
                  transitionDuration="auto"
                >
                  <div className={root}>
                    <div className={clsx(header, borderBottom, closeButton)}>
                      <SvgIcon
                        onClick={() => {
                          close();
                        }}
                        data-testid="menu-appbar-close"
                      >
                        <Close />
                      </SvgIcon>
                    </div>
                    <div
                      className={clsx(header, borderBottom)}
                      data-testid="menu-appbar-header"
                    >
                      DICE
                    </div>
                    <Accordion
                      onClick={() => setAccordionOpen(!accordionOpen)}
                      className={accordion}
                    >
                      <AccordionSummary expandIcon={<DownArrow />}>
                        <div
                          className={clsx(header)}
                          data-testid="menu-appbar-site-selection"
                        >
                          Site selection
                        </div>
                      </AccordionSummary>
                    </Accordion>
                    {accordionOpen &&
                      uiSend &&
                      sites &&
                      sites.map(({ displayName, id }) => (
                        <Button
                          key={id}
                          data-testid={`menu-appbar-site-selection-${displayName}`}
                          onClick={() => {
                            uiSend({
                              type: "SELECT_SITE",
                              id,
                              siteName: displayName,
                            });
                            close();
                          }}
                          className={siteButton}
                        >
                          {displayName}
                        </Button>
                      ))}
                  </div>
                </Menu>
              </Box>
            </Grid>
            <Grid item>
              <Box paddingX={2}>
                <Typography
                  align="center"
                  variant="h2"
                  data-testid="diceHeader"
                >
                  {text}
                  {environment.env === "dev" &&
                    environment.buildInfo &&
                    environment.buildInfo.branchName && (
                      <>
                        <span> - </span>
                        <span
                          style={{
                            background:
                              environment.buildInfo.branchName === "master"
                                ? "greenyellow"
                                : "yellow",
                          }}
                        >
                          {environment.buildInfo.branchName}
                        </span>
                      </>
                    )}
                  {environment.env === "dev" &&
                    environment.appSync.endpoint.includes(
                      "api.dev.ice.devtest"
                    ) && (
                      <>
                        <span> - </span>
                        <span
                          style={{
                            background: "cyan",
                          }}
                        >
                          Using dev AppSync
                        </span>
                      </>
                    )}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box width={48}>
                <IconButton
                  data-testid="menu-appbar-user"
                  aria-label={t`User`}
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  edge="end"
                >
                  <Avatar />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  keepMounted
                  open={isOpen}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      height: "auto",
                      width: 192,
                    },
                  }}
                >
                  <Typography className={label}>{t`User Role`}</Typography>
                  <MenuItem
                    className={userRole}
                    data-testid="menu-appbar-user-role"
                  >{t`Field operator`}</MenuItem>
                  <MenuItem
                    onClick={handleLogout}
                    className={logout}
                    data-testid="menu-appbar-logout"
                  >
                    <Icons.ExitToApp />
                    {t`Logout`}
                  </MenuItem>
                </Menu>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </Box>
    </AppBar>
  );
};

export default Header;
