import React, { useEffect } from "react";
import { t } from "ttag";
import { useUiService } from "@src/hooks";
import { Header } from "@src/containers";
import { getAreasPromise } from "@ats/graphql";
import { Backdrop, Box, CircularProgress } from "@src/components";
import Sites from "./Sites.container";

const SitesData = () => {
  const [uiState, uiSend] = useUiService();
  const { sites } = uiState.context;
  const noSiteData = sites && sites.length && sites.length < 1;

  useEffect(() => {
    getAreasPromise().then((response) => {
      uiSend({ type: "FETCH_SITES", sites: response });
    });
  }, [uiSend]);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Box
        style={{
          position: "relative",
          width: "100%",
          flexShrink: 0,
        }}
      >
        <Header
          uiState={uiState}
          uiSend={uiSend}
          text={t`DICE`}
          sites={sites}
          siteMenu
        />
      </Box>
      <Box
        style={{
          marginTop: "20px",
          flexGrow: 1,
        }}
      >
        {!noSiteData && (
          <Sites uiState={uiState} uiSend={uiSend} areas={sites} />
        )}
      </Box>

      {noSiteData && (
        <Backdrop open>
          <CircularProgress color="primary" />
        </Backdrop>
      )}
    </Box>
  );
};

export default SitesData;
