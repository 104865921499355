/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import { AwsRum } from "aws-rum-web";
import { BrowserRouter, Route, Switch, RouteProps } from "react-router-dom";
import moment from "moment";
import ConnectionProviders from "@src/providers/ConnectionProviders";
import { ROUTE_ROOT, routes } from "@src/constants/routes";
import Authenticated from "@src/views/Authenticated.view";
import {
  Box,
  Container,
  Backdrop,
  CircularProgress,
  Typography,
} from "@src/components";
import { Toolbar } from "@material-ui/core";
import { useKeycloak } from "@react-keycloak/web";
import {
  saveStaff,
  getAppMonitorConfigurationPromise,
  initLog,
  doLog,
  LogLevel,
  LogClient,
  LogEntryType,
} from "@ats/graphql";
import { lastConnected } from "@src/components/Model/observables";
import { configureAmplify, IUserAttributes } from "../setup";

const ProtectedRoute: React.FC<RouteProps> = () => {
  return (
    <ConnectionProviders>
      <Authenticated />
    </ConnectionProviders>
  );
};

const Router = () => {
  const { keycloak } = useKeycloak();
  const isAuthenticated = keycloak.authenticated;
  const [user, setUser] = useState<IUserAttributes | null>(null);
  const authenticated = user !== undefined;
  let rumClient: AwsRum | null = null;

  useEffect(() => {
    if (isAuthenticated) {
      const token = keycloak.tokenParsed && keycloak.tokenParsed;
      const userAttributes = token as IUserAttributes;
      (window as any).external_staff_reference =
        !!token && token.customer.external_staff_reference;
      configureAmplify();
      setUser(userAttributes);

      if (lastConnected.getValue() === undefined)
        lastConnected.next(moment().startOf("second").toISOString());
    }
  }, [isAuthenticated, keycloak.tokenParsed]);

  useEffect(() => {
    if (!isAuthenticated) return;
    if (window.location.hostname === "localhost") return;
    getAppMonitorConfigurationPromise()
      // eslint-disable-next-line consistent-return
      .then((rumConfiguration) => {
        if (rumConfiguration.id === "") return null;

        const {
          guestRoleArn,
          identityPoolId,
          allowCookies,
          enableXRay,
          sessionSampleRate,
        } = rumConfiguration.appMonitorConfiguration;
        const { endpoint, version, region } = rumConfiguration.tags;

        const applicationId = rumConfiguration.id;
        const applicationVersion = version;

        // eslint-disable-next-line react-hooks/exhaustive-deps
        rumClient = new AwsRum(applicationId, applicationVersion, region, {
          sessionSampleRate,
          sessionEventLimit: 0, // no limit
          guestRoleArn,
          identityPoolId,
          endpoint,
          telemetries: [["errors", { stackTraceLength: 10000 }], "http"],
          allowCookies,
          enableXRay,
        });

        initLog(
          LogClient.DICE,
          rumClient
            ? {
                callbackFn: (logEntry) => {
                  if (rumClient)
                    rumClient.recordEvent(`dice_${logEntry.level}_event`, {
                      details: logEntry,
                    });
                },
              }
            : undefined
        );

        doLog(LogLevel.Debug, {
          type: LogEntryType.UI,
          message: `Rum monitor initializated`,
        });
      })
      .catch(() => {
        doLog(LogLevel.Error, {
          type: LogEntryType.UI,
          message: `Error in rum monitor initialization`,
        });
      });
  }, [isAuthenticated]);

  useEffect(() => {
    const token = keycloak.tokenParsed && keycloak.tokenParsed;

    if (token) {
      // Add 6 months to the current date and convert the epoch timestamp in seconds
      const expirationTimestamp = Math.floor(
        new Date().setUTCMonth(new Date().getUTCMonth() + 6) / 1000
      );
      saveStaff({
        givenName: token.given_name,
        familyName: token.family_name,
        externalStaffReference: token.customer.external_staff_reference,
        expirationTimestamp,
      });
    }
  }, [keycloak.tokenParsed]);

  const [connected, setConnected] = useState<boolean>(true);

  // Listen to the lastConnected observable which is updated when we
  // receive equipmentStatus updates in Vehicles.provider.tsx
  // TODO: Once the graphql package expose a timestamp for any received data
  // we can change to use an interval to regularly check the age of data
  // and set connected stated depending on that
  useEffect(() => {
    const sub = lastConnected.subscribe((timestamp) => {
      if (!timestamp && connected) {
        setConnected(false);
      } else if (timestamp && !connected) {
        setConnected(true);
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, [connected]);

  return (
    <BrowserRouter>
      <>
        <Box display="flex" flexDirection="column" minHeight={1} mt={10}>
          {authenticated && <Toolbar />}

          <Backdrop
            style={{
              background: "#555",
              color: "#000",
              zIndex: 1000,
              opacity: 0.6,
            }}
            open={!connected}
          >
            <>
              <CircularProgress color="inherit" />
              <Typography style={{ paddingLeft: "10px" }}>
                Waiting for connection...
              </Typography>
            </>
          </Backdrop>

          <Box flex="auto" display="flex">
            <Container component="main" disableGutters maxWidth="sm">
              <Switch>
                {routes.map(({ path, component }) => (
                  <Route
                    path={path}
                    component={component}
                    key={path as string}
                  />
                ))}
                <ProtectedRoute path={ROUTE_ROOT} />
              </Switch>
            </Container>
          </Box>
        </Box>
      </>
    </BrowserRouter>
  );
};

export default Router;
